import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/dashboard/index.vue'),
      },
      {
        path: '/organization',
        name: 'organization',
        component: () => import('../views/organization/index.vue'),
      },
      {
        path: '/organization/add',
        name: 'organizationAdd',
        component: () => import('../views/organization/add.vue'),
      },
      {
        path: '/organization/edit',
        name: 'organizationEdit',
        component: () => import('../views/organization/edit.vue'),
      },
      {
        path: '/password',
        name: 'password',
        component: () => import('../views/password/index.vue'),
      },
      {
        path: '/adminis',
        name: 'adminis',
        component: () => import('../views/adminis/index.vue'),
      },
      {
        path: '/adminis/add',
        name: 'adminisAdd',
        component: () => import('../views/adminis/add.vue'),
      },
      {
        path: '/adminis/edit',
        name: 'adminisEdit',
        component: () => import('../views/adminis/edit.vue'),
      },
      {
        path: '/adminis/detail',
        name: 'adminisDetail',
        component: () => import('../views/adminis/detail.vue'),
      },
      {
        path: '/company',
        name: 'company',
        component: () => import('../views/company/index.vue'),
      },
      {
        path: '/company/add',
        name: 'companyAdd',
        component: () => import('../views/company/add.vue'),
      },
      {
        path: '/company/edit',
        name: 'companyEdit',
        component: () => import('../views/company/edit.vue'),
      },
      {
        path: '/company/detail',
        name: 'companyDetail',
        component: () => import('../views/company/detail.vue'),
      },
      {
        path: '/menuManage',
        name: 'menuManage',
        component: () => import('../views/menuManage/index.vue'),
      },
      {
        path: '/menuManage/add',
        name: 'menuManageAdd',
        component: () => import('../views/menuManage/add.vue'),
      },
      {
        path: '/menuManage/edit',
        name: 'menuManageEdit',
        component: () => import('../views/menuManage/edit.vue'),
      },
      {
        path: '/jobs',
        name: 'jobs',
        component: () => import('../views/jobs/index.vue'),
      },
      {
        path: '/jobs/add',
        name: 'jobsAdd',
        component: () => import('../views/jobs/add.vue'),
      },
      {
        path: '/jobs/edit',
        name: 'jobsEdit',
        component: () => import('../views/jobs/edit.vue'),
      },
      {
        path: '/staff',
        name: 'staff',
        component: () => import('../views/staff/index.vue'),
      },
      {
        path: '/staff/add',
        name: 'staffAdd',
        component: () => import('../views/staff/add.vue'),
      },
      {
        path: '/staff/edit',
        name: 'staffEdit',
        component: () => import('../views/staff/edit.vue'),
      },
      {
        path: '/staff/detail',
        name: 'staffDetail',
        component: () => import('../views/staff/detail.vue'),
      },
      {
        path: '/robot',
        name: 'robot',
        component: () => import('../views/robot/index.vue'),
      },
      {
        path: '/robot/add',
        name: 'robotAdd',
        component: () => import('../views/robot/add.vue'),
      },
      {
        path: '/robot/edit',
        name: 'robotEdit',
        component: () => import('../views/robot/edit.vue'),
      },
      {
        path: '/robot/detail',
        name: 'robotDetail',
        component: () => import('../views/robot/detail.vue'),
      },
      {
        path: '/deviceSort',
        name: 'deviceSort',
        component: () => import('../views/deviceSort/index.vue'),
      },
      {
        path: '/deviceSort/add',
        name: 'deviceSortAdd',
        component: () => import('../views/deviceSort/add.vue'),
      },
      {
        path: '/deviceSort/edit',
        name: 'deviceSortEdit',
        component: () => import('../views/deviceSort/edit.vue'),
      },
      {
        path: '/facilitySort',
        name: 'facilitySort',
        component: () => import('../views/facilitySort/index.vue'),
      },
      {
        path: '/facilitySort/add',
        name: 'facilitySortAdd',
        component: () => import('../views/facilitySort/add.vue'),
      },
      {
        path: '/facilitySort/edit',
        name: 'facilitySortEdit',
        component: () => import('../views/facilitySort/edit.vue'),
      },
      {
        path: '/deviceList',
        name: 'deviceList',
        component: () => import('../views/deviceList/index.vue'),
      },
      {
        path: '/deviceList/add',
        name: 'deviceListAdd',
        component: () => import('../views/deviceList/add.vue'),
      },
      {
        path: '/deviceList/edit',
        name: 'deviceListEdit',
        component: () => import('../views/deviceList/edit.vue'),
      },
      {
        path: '/deviceList/detail',
        name: 'deviceListDetail',
        component: () => import('../views/deviceList/detail.vue'),
      },
      {
        path: '/deviceScrap',
        name: 'deviceScrap',
        component: () => import('../views/deviceScrap/index.vue'),
      },
      {
        path: '/deviceScrap/detail',
        name: 'deviceScrapDetail',
        component: () => import('../views/deviceScrap/detail.vue'),
      },
      {
        path: '/dynamiccheck',
        name: 'dynamiccheck',
        component: () => import('../views/dynamic/check.vue'),
      },
      {
        path: '/dynamicpatrol',
        name: 'dynamicpatrol',
        component: () => import('../views/dynamic/patrol.vue'),
      },
      {
        path: '/cycle',
        name: 'cycle',
        component: () => import('../views/cycle/index.vue'),
      },
      {
        path: '/cycle/add',
        name: 'cycleAdd',
        component: () => import('../views/cycle/add.vue'),
      },
      {
        path: '/cycle/detail',
        name: 'cycleDetail',
        component: () => import('../views/cycle/detail.vue'),
      },
      {
        path: '/cycle/copys',
        name: 'cycleCopys',
        component: () => import('../views/cycle/copys.vue'),
      },
      {
        path: '/cycle/edit',
        name: 'cycleEdit',
        component: () => import('../views/cycle/edit.vue'),
      },
      {
        path: '/cycleRecordExport',
        name: 'cycleRecordExport',
        component: () => import('../views/cycleRecordExport/index.vue'),
      },

      {
        path: '/cycleUndetectedReset',
        name: 'cycleUndetectedReset',
        component: () => import('../views/cycleUndetectedReset/index.vue'),
      },
      {
        path: '/cycleReset',
        name: 'cycleReset',
        component: () => import('../views/cycleUndetectedReset/reset.vue'),
      },

      {
        path: '/check',
        name: 'check',
        component: () => import('../views/check/index.vue'),
      },
      {
        path: '/check/add',
        name: 'checkAdd',
        component: () => import('../views/check/add.vue'),
      },
      {
        path: '/check/detail',
        name: 'checkDetail',
        component: () => import('../views/check/detail.vue'),
      },
      {
        path: '/check/copys',
        name: 'checkCopys',
        component: () => import('../views/check/copys.vue'),
      },
      {
        path: '/check/edit',
        name: 'checkEdit',
        component: () => import('../views/check/edit.vue'),
      },
      {
        path: '/checkRecordExport',
        name: 'checkRecordExport',
        component: () => import('../views/checkRecordExport/index.vue'),
      },
      {
        path: '/checkUndetectedReset',
        name: 'checkUndetectedReset',
        component: () => import('../views/checkUndetectedReset/index.vue'),
      },
      {
        path: '/checkReset',
        name: 'checkReset',
        component: () => import('../views/checkUndetectedReset/reset.vue'),
      },
      {
        path: '/patrol',
        name: 'patrol',
        component: () => import('../views/patrol/index.vue'),
      },
      {
        path: '/patrol/add',
        name: 'patrolAdd',
        component: () => import('../views/patrol/add.vue'),
      },
      {
        path: '/patrol/detail',
        name: 'patrolDetail',
        component: () => import('../views/patrol/detail.vue'),
      },
      {
        path: '/patrol/edit',
        name: 'patrolEdit',
        component: () => import('../views/patrol/edit.vue'),
      },
      {
        path: '/recordPoint', // 点检
        name: 'recordPoint',
        component: () => import('../views/record/point/index.vue'),
      },
      {
        path: '/recordPoint/detail',
        name: 'recordPointDetail',
        component: () => import('../views/record/point/detail.vue'),
      },
      {
        path: '/recordPoint/deviceDetail',
        name: 'recordPointDeviceDetail',
        component: () => import('../views/record/point/deviceDetail.vue'),
      },
      {
        path: '/recordCycle', // 巡检
        name: 'recordCycle',
        component: () => import('../views/record/cycle/index.vue'),
      },
      {
        path: '/recordCycle/detail',
        name: 'recordCycleDetail',
        component: () => import('../views/record/cycle/detail.vue'),
      },
      {
        path: '/recordCycle/deviceDetail',
        name: 'recordCycleDeviceDetail',
        component: () => import('../views/record/cycle/deviceDetail.vue'),
      },
      {
        path: '/overhaulTask', // 检修计划
        name: 'overhaulTask',
        component: () => import('../views/overhaulTask/index.vue'),
      },
      {
        path: '/overhaulTask/add',
        name: 'overhaulTaskAdd',
        component: () => import('../views/overhaulTask/add.vue'),
      },
      {
        path: '/overhaulTask/detail',
        name: 'overhaulTaskDetail',
        component: () => import('../views/overhaulTask/detail.vue'),
      },
      {
        path: '/overhaulTask/edit',
        name: 'overhaulTaskEdit',
        component: () => import('../views/overhaulTask/edit.vue'),
      },
      {
        path: '/overhaulDynamic', // 检修任务
        name: 'overhaulDynamic',
        component: () => import('../views/overhaulDynamic/index.vue'),
      },
      {
        path: '/overhaulDynamic/detail',
        name: 'overhaulDynamicDetail',
        component: () => import('../views/overhaulDynamic/detail.vue'),
      },
      {
        path: '/overhaulDynamic/edit',
        name: 'overhaulDynamicEdit',
        component: () => import('../views/overhaulDynamic/edit.vue'),
      },
      {
        path: '/overhaulRecord', // 检修记录
        name: 'overhaulRecord',
        component: () => import('../views/overhaulRecord/index.vue'),
      },
      {
        path: '/overhaulRecord/detail',
        name: 'overhaulRecordDetail',
        component: () => import('../views/overhaulRecord/detail.vue'),
      },
      {
        path: '/cycleTurn/add',
        name: 'cycleTurnAdd',
        component: () => import('../views/overhaulTask/cycleTurn/add.vue'),
      },
      {
        path: '/checkTurn/add',
        name: 'checkTurnAdd',
        component: () => import('../views/overhaulTask/checkTurn/add.vue'),
      },
      {
        path: '/lubricationTask', // 润滑计划
        name: 'lubricationTask',
        component: () => import('../views/lubricationTask/index.vue')
      },
      {
        path: '/lubricationTask/add',
        name: 'lubricationTaskAdd',
        component: () => import('../views/lubricationTask/add.vue')
      },
      {
        path: '/lubricationTask/detail',
        name: 'lubricationTaskDetail',
        component: () => import('../views/lubricationTask/detail.vue')
      },
      {
        path: '/lubricationTask/edit',
        name: 'lubricationTaskEdit',
        component: () => import('../views/lubricationTask/edit.vue')
      },
      {
        path: '/lubricationDynamic', // 润滑任务
        name: 'lubricationDynamic',
        component: () => import('../views/lubricationDynamic/index.vue')
      },
      {
        path: '/lubricationRecord', // 润滑记录
        name: 'lubricationRecord',
        component: () => import('../views/lubricationRecord/index.vue')
      },
      {
        path: '/lubricationRecord/detail',
        name: 'lubricationRecordDetail',
        component: () => import('../views/lubricationRecord/detail.vue')
      },
      {
        path: '/lubricaOilManage', // 润滑油管理
        name: 'lubricaOilManage',
        component: () => import('../views/lubricaOilManage/index.vue')
      },
      {
        path: '/lubricaOilManage/archives/add', // 创建档案
        name: 'archivesAdd',
        component: () => import('../views/lubricaOilManage/archives/add.vue')
      },
      {
        path: '/lubricaOilManage/archives/edit',
        name: 'archivesEdit',
        component: () => import('../views/lubricaOilManage/archives/edit.vue')
      },
      {
        path: '/lubricaOilManage/archives/detail',
        name: 'archivesDetail',
        component: () => import('../views/lubricaOilManage/archives/detail.vue')
      },
      {
        path: '/lubricaOilManage/brand/add', // 创建品牌
        name: 'brandAdd',
        component: () => import('../views/lubricaOilManage/brand/add.vue')
      },
      {
        path: '/lubricaOilManage/brand/edit',
        name: 'brandEdit',
        component: () => import('../views/lubricaOilManage/brand/edit.vue')
      },
      {
        path: '/lubricaOilManage/classification/add', // 创建分类
        name: 'classificationAdd',
        component: () => import('../views/lubricaOilManage/classification/add.vue')
      },
      {
        path: '/lubricaOilManage/classification/edit',
        name: 'classificationEdit',
        component: () => import('../views/lubricaOilManage/classification/edit.vue')
      },
      {
        path: '/lubricaOilManage/level/add', // 创建级别
        name: 'levelAdd',
        component: () => import('../views/lubricaOilManage/level/add.vue')
      },
      {
        path: '/lubricaOilManage/level/edit',
        name: 'levelEdit',
        component: () => import('../views/lubricaOilManage/level/edit.vue')
      },
      {
        path: '/overviewPipelineNetwork', // 管网概况
        name: 'overviewPipelineNetwork',
        component: () => import('../views/overviewPipelineNetwork/index.vue'),
      },
      {
        path: '/overviewPipelineNetwork/detail',
        name: 'overviewPipelineNetworkDetail',
        component: () => import('../views/overviewPipelineNetwork/detail.vue'),
      },
      {
        path: '/pipelineDisplay', // 管网展示
        name: 'pipelineDisplay',
        component: () => import('../views/pipelineDisplay/index.vue'),
      },
      {
        path: '/pipelineCycle', // 管网巡检
        name: 'pipelineCycle',
        component: () => import('../views/pipelineCycle/index.vue'),
      },
      {
        path: '/pipeline', // 管线列表
        name: 'pipeline',
        component: () => import('../views/pipeline/index.vue'),
      },
      {
        path: '/pipeline/add',
        name: 'pipelineAdd',
        component: () => import('../views/pipeline/add.vue'),
      },
      {
        path: '/iotProduct', // iot设备类型
        name: 'iotProduct',
        component: () => import('../views/iotProduct/index.vue'),
      },
      {
        path: '/iotEquipment', // iot设备信息
        name: 'iotEquipment',
        component: () => import('../views/iotEquipment/index.vue'),
      },
      {
        path: '/iotEquipment/add', 
        name: 'iotEquipmentAdd',
        component: () => import('../views/iotEquipment/add.vue'),
      },
      {
        path: '/iotEquipment/detail', 
        name: 'iotEquipmentDetail',
        component: () => import('../views/iotEquipment/detail.vue'),
      },
      {
        path: '/iotEquipment/record', 
        name: 'iotEquipmentRecord',
        component: () => import('../views/iotEquipment/record.vue'),
      },
      {
        path: '/iotEquipment/subEquipment', 
        name: 'iotSubEquipment',
        component: () => import('../views/iotEquipment/subEquipment.vue'),
      },
      {
        path: '/iotEquipment/config', 
        name: 'iotEquipmentConfig',
        component: () => import('../views/iotEquipment/config.vue'),
      },
      {
        path: '/iotEquipment/bind', 
        name: 'iotEquipmentBind',
        component: () => import('../views/iotEquipment/bind.vue'),
      },
      

      {
        path: '/iotSupplier', // iot供应商管理
        name: 'iotSupplier',
        component: () => import('../views/iotSupplier/index.vue'),
      },
      {
        path: '/iotSupplier/add', 
        name: 'iotSupplierAdd',
        component: () => import('../views/iotSupplier/add.vue'),
      },
      {
        path: '/iotSupplierDevice', // iot供应商设备
        name: 'iotSupplierDevice',
        component: () => import('../views/iotSupplierDevice/index.vue'),
      },
      {
        path: '/iotSupplierDevice/add', 
        name: 'iotSupplierDeviceAdd',
        component: () => import('../views/iotSupplierDevice/add.vue'),
      },

      {
        path: '/iotReportFirst', // iot设备上报1
        name: 'iotReportFirst',
        component: () => import('../views/iotReportFirst/index.vue'),
      },
      {
        path: '/iotReportFirstRecord',
        name: 'iotReportFirstRecord',
        component: () => import('../views/iotReportFirst/record.vue'),
      },

      {
        path: '/iotReportSecond', // iot设备上报2
        name: 'iotReportSecond',
        component: () => import('../views/iotReportSecond/index.vue'),
      },
      {
        path: '/iotReportSecondRecord',
        name: 'iotReportSecondRecord',
        component: () => import('../views/iotReportSecond/record.vue'),
      },

      {
        path: '/iotTwinsManage', // iot孪生体管理
        name: 'iotTwinsManage',
        component: () => import('../views/iotTwinsManage/index.vue'),
      },
      {
        path: '/iotTwinsManage/bind', // iot孪生体绑定实体设备
        name: 'iotTwinsManageBind',
        component: () => import('../views/iotTwinsManage/bind.vue'),
      },
      {
        path: '/iotTwinsManage/detail',
        name: 'iotTwinsManageDetail',
        component: () => import('../views/iotTwinsManage/detail.vue'),
      },
      {
        path: '/iotTwinsManage/edit',
        name: 'iotTwinsManageEdit',
        component: () => import('../views/iotTwinsManage/edit.vue'),
      },

      {
        path: '/iotClassifiTwins', // iot孪生体分类
        name: 'iotClassifiTwins',
        component: () => import('../views/classifiTwins/index.vue'),
      },
      {
        path: '/iotClassifiTwins/add',
        name: 'iotClassifiTwinsAdd',
        component: () => import('../views/classifiTwins/add.vue'),
      },
      {
        path: '/iotClassifiTwins/edit',
        name: 'iotClassifiTwinsEdit',
        component: () => import('../views/classifiTwins/edit.vue'),
      },
      {
        path: '/iotConfigExhib', // iot组态展示
        name: 'iotConfigExhib',
        component: () => import('../views/iotConfigExhib/detail.vue'),
      },
      {
        path: '/iotConfigExhib/detail',
        name: 'iotConfigExhibDetail',
        component: () => import('../views/iotConfigExhib/detail.vue'),
      },
      {
        path: '/iotConfigExhib/statistics',
        name: 'iotConfigExhibStatistics',
        component: () => import('../views/iotConfigExhib/statistics.vue'),
      },

      {
        path: '/iotConfigManage', // iot组态管理
        name: 'iotConfigManage',
        component: () => import('../views/iotConfigManage/index.vue'),
      },
      {
        path: '/iotConfigManage/conProjectAdd', // iot组态项目创建
        name: 'iotConfigManageConProjectAdd',
        component: () => import('../views/iotConfigManage/conProjectAdd.vue'),
      },
      {
        path: '/iotConfigManage/conProjectEdit', // iot组态项目编辑
        name: 'iotConfigManageConProjectEdit',
        component: () => import('../views/iotConfigManage/conProjectEdit.vue'),
      },
      {
        path: '/iotConfigManage/baseAdd',
        name: 'iotConfigManageBaseAdd',
        component: () => import('../views/iotConfigManage/baseAdd.vue'),
      },
      {
        path: '/iotConfigManage/configAdd',
        name: 'iotConfigManageConfigAdd',
        component: () => import('../views/iotConfigManage/configAdd.vue'),
      },
      {
        path: '/iotConfigManage/baseEdit',
        name: 'iotConfigManageBaseEdit',
        component: () => import('../views/iotConfigManage/baseEdit.vue'),
      },
      {
        path: '/iotConfigManage/configEdit',
        name: 'iotConfigManageConfigEdit',
        component: () => import('../views/iotConfigManage/configEdit.vue'),
      },
      {
        path: '/iotConfigManage/detail',
        name: 'iotConfigManageDetail',
        component: () => import('../views/iotConfigManage/detail.vue'),
      },
      {
        path: '/iotConfigManage/statistics',
        name: 'iotConfigManageStatistics',
        component: () => import('../views/iotConfigManage/statistics.vue'),
      },

      {
        path: '/iotResource', // iot组态资源
        name: 'iotResource',
        component: () => import('../views/iotResource/index.vue'),
      },
      {
        path: '/iotResource/add',
        name: 'iotResourceAdd',
        component: () => import('../views/iotResource/add.vue'),
      },

      {
        path: '/iotConfigRecord', // iot组态记录
        name: 'iotConfigRecord',
        component: () => import('../views/iotConfigRecord/index.vue'),
      },
      {
        path: '/iotDataStatistics', // iot数据统计
        name: 'iotDataStatistics',
        component: () => import('../views/iotDataStatistics/index.vue'),
      },
      {
        path: '/chartConfig', // iot图表配置
        name: 'chartConfig',
        component: () => import('../views/chartConfig/index.vue'),
      },
      {
        path: '/chartConfig/add',
        name: 'chartConfigAdd',
        component: () => import('../views/chartConfig/add.vue'),
      },
      {
        path: '/chartConfig/edit',
        name: 'chartConfigEdit',
        component: () => import('../views/chartConfig/edit.vue'),
      },
      {
        path: '/chartConfig/addClassify',
        name: 'chartConfigAddClassify',
        component: () => import('../views/chartConfig/addClassify.vue'),
      },
      {
        path: '/chartConfig/editClassify',
        name: 'chartConfigEditClassify',
        component: () => import('../views/chartConfig/editClassify.vue'),
      },
      {
        path: '/iotDataReport', // 数据报表
        name: 'iotDataReport',
        component: () => import('../views/iotDataReport/index.vue'),
      },
      {
        path: '/iotDataPosiCumuStat', // 正累计流量数据统计
        name: 'iotDataPosiCumuStat',
        component: () => import('../views/iotDataPosiCumuStat/index.vue'),
      },
      {
        path: '/archivRet', // 档案检索
        name: 'archivRet',
        component: () => import('../views/archivRet/index.vue'),
      },
      {
        path: '/archivRet/detail',
        name: 'archivRetDetail',
        component: () => import('../views/archivRet/detail.vue'),
      },
      {
        path: '/archivRet/patrol/detail',
        name: 'aPatrolDetail',
        component: () => import('../views/archivRet/patrol/detail.vue'),
      },
      {
        path: '/archivRet/check/detail',
        name: 'aCheckDetail',
        component: () => import('../views/archivRet/check/detail.vue'),
      },
      {
        path: '/archivRet/overhaul/detail',
        name: 'aOverhaulDetail',
        component: () => import('../views/archivRet/overhaul/detail.vue'),
      },
      {
        path: '/archivRet/lube/detail',
        name: 'aLubeDetail',
        component: () => import('../views/archivRet/lubrication/detail.vue'),
      },
      {
        path: '/alarmEvent', // 告警事件
        name: 'alarmEvent',
        component: () => import('../views/alarmEvent/index.vue'),
      },
      {
        path: '/alarmRules', // 告警规则
        name: 'alarmRules',
        component: () => import('../views/alarmRules/index.vue'),
      },
      {
        path: '/alarmRules/add',
        name: 'alarmRulesAdd',
        component: () => import('../views/alarmRules/add.vue'),
      },
      {
        path: '/alarmRules/edit',
        name: 'alarmRulesEdit',
        component: () => import('../views/alarmRules/edit.vue'),
      },
      {
        path: '/alarmRules/detail',
        name: 'alarmRulesDetail',
        component: () => import('../views/alarmRules/detail.vue'),
      },
      {
        path: '/daily', // 日报
        name: 'daily',
        component: () => import('../views/daily/index.vue'),
      },
      {
        path: '/stayDownloadList', // 待下载列表
        name: 'stayDownloadList',
        component: () => import('../views/stayDownloadList/index.vue'),
      },

      {
        path: '/alarmGroupList', // 报警组列表
        name: 'alarmGroupList',
        component: () => import('../views/alarmGroupList/index.vue'),
      },
      {
        path: '/alarmConfig', // 任务规则配置（所属部门）
        name: 'alarmConfig',
        component: () => import('../views/alarmConfig/index.vue'),
      },
      {
        path: '/knowledgeList', // 知识库列表
        name: 'knowledgeList',
        component: () => import('../views/knowledgeList/index.vue'),
      },
      {
        path: '/knowledgeList/add',
        name: 'knowledgeListAdd',
        component: () => import('../views/knowledgeList/add.vue'),
      },
      {
        path: '/knowledgeList/detail',
        name: 'knowledgeListDetail',
        component: () => import('../views/knowledgeList/detail.vue'),
      },
      {
        path: '/knowledgeList/edit',
        name: 'knowledgeListEdit',
        component: () => import('../views/knowledgeList/edit.vue'),
      },
      {
        path: '/columnClassify', // 栏目分类
        name: 'columnClassify',
        component: () => import('../views/columnClassify/index.vue'),
      },
      {
        path: '/columnClassify/add',
        name: 'columnClassifyAdd',
        component: () => import('../views/columnClassify/add.vue'),
      },
      {
        path: '/columnClassify/edit',
        name: 'columnClassifyEdit',
        component: () => import('../views/columnClassify/edit.vue'),
      },
      {
        path: '/cycleAchievements', // 个人绩效
        name: 'cycleAchievements',
        component: () => import('../views/cycleAchievements/index.vue'),
      },
      {
        path: '/depAchievements', // 部门绩效
        name: 'depAchievements',
        component: () => import('../views/depAchievements/index.vue'),
      },
      {
        path: '/analysisPatrol', // 数据分析 巡视点
        name: 'analysisPatrol',
        component: () => import('../views/analysis/patrol/index.vue'),
      },
      {
        path: '/analysisPatrol/detail',
        name: 'analysisPatrolDetail',
        component: () => import('../views/analysis/patrol/detail.vue'),
      },
      {
        path: '/analysisDevice', // 数据分析 设备
        name: 'analysisDevice',
        component: () => import('../views/analysis/device/index.vue'),
      },
      {
        path: '/analysisDevice/detail',
        name: 'analysisDeviceDetail',
        component: () => import('../views/analysis/device/detail.vue'),
      },

      {
        path: '/recordAbnormal',
        name: 'recordAbnormal',
        component: () => import('../views/record/abnormal/index.vue'),
      },
      {
        path: '/recordAbnormal/detail',
        name: 'recordAbnormalDetail',
        component: () => import('../views/record/abnormal/detail.vue'),
      }, 
      {
        path: '/recordAbnormal/overAdd', // 异常转检修
        name: 'recordAbnormalOverAdd',
        component: () => import('../views/record/abnormal/overAdd.vue'),
      },
      {
        path: '/recordOmissions', // 漏检任务
        name: 'recordOmissions',
        component: () => import('../views/record/omissions/index.vue'),
      },
      {
        path: '/recordOmissions/detail',
        name: 'recordOmissionsDetail',
        component: () => import('../views/record/omissions/detail.vue'),
      },
      {
        path: '/recordOmissions/deviceDetail',
        name: 'recordOmissionsDeviceDetail',
        component: () => import('../views/record/omissions/deviceDetail.vue'),
      },

      {
        path: '/recordRepair', // 维修任务
        name: 'recordRepair',
        component: () => import('../views/record/repair/index.vue'),
      },
      {
        path: '/recordRepair/detail', // 维修任务
        name: 'recordRepairDetail',
        component: () => import('../views/record/repair/detail.vue'),
      },

      {
        path: '/routineTask', // 外勤计划
        name: 'routineTask',
        component: () => import('../views/routineTask/index.vue'),
      },
      {
        path: '/routineTask/add',
        name: 'routineTaskAdd',
        component: () => import('../views/routineTask/add.vue'),
      },
      {
        path: '/routineTask/detail',
        name: 'routineTaskDetail',
        component: () => import('../views/routineTask/detail.vue'),
      },
      {
        path: '/routineTask/edit',
        name: 'routineTaskEdit',
        component: () => import('../views/routineTask/edit.vue'),
      },
      {
        path: '/fieldTask', // 外勤任务
        name: 'fieldTask',
        component: () => import('../views/fieldTask/index.vue'),
      },
      {
        path: '/fieldTask/detail',
        name: 'fieldTaskDetail',
        component: () => import('../views/fieldTask/detail.vue'),
      },

      {
        path: '/directionalTask',
        name: 'directionalTask',
        component: () => import('../views/directionalTask/index.vue'),
      },
      {
        path: '/directionalTask/add',
        name: 'directionalTaskAdd',
        component: () => import('../views/directionalTask/add.vue'),
      },
      {
        path: '/directionalTask/detail',
        name: 'directionalTaskDetail',
        component: () => import('../views/directionalTask/detail.vue'),
      },
      {
        path: '/directionalTask/edit',
        name: 'directionalTaskEdit',
        component: () => import('../views/directionalTask/edit.vue'),
      },
      {
        path: '/direcPatrolTurn/add',
        name: 'direcPatrolTurnAdd',
        component: () => import('../views/directionalTask/patrolTurn/add.vue'),
      },
      {
        path: '/direcFaultTurn/add',
        name: 'direcFaultTurnAdd',
        component: () => import('../views/directionalTask/faultTurn/add.vue'),
      },

      {
        path: '/faultReport',
        name: 'faultReport',
        component: () => import('../views/faultReport/index.vue'),
      },
      {
        path: '/faultReport/detail',
        name: 'faultReportDetail',
        component: () => import('../views/faultReport/detail.vue'),
      },

      {
        path: '/patrolReport',
        name: 'patrolReport',
        component: () => import('../views/patrolReport/index.vue'),
      },
      {
        path: '/patrolReport/detail',
        name: 'patrolReportDetail',
        component: () => import('../views/patrolReport/detail.vue'),
      },
      
      {
        path: '/onlineStatus', // 轨迹管理
        name: 'onlineStatus',
        component: () => import('../views/onlineStatus/index.vue'),
      },
      {
        path: '/staffTrack',
        name: 'staffTrack',
        component: () => import('../views/staffTrack/index.vue'),
      },
      {
        path: '/staffOdometer',
        name: 'staffOdometer',
        component: () => import('../views/staffOdometer/index.vue'),
      },
      {
        path: '/staffTrackTable',
        name: 'staffTrackTable',
        component: () => import('../views/staffTrackTable/index.vue'),
      },
      {
        path: '/attendance',
        name: 'attendance',
        component: () => import('../views/attendance/index.vue'),
      },
      {
        path: '/attendance/detail',
        name: 'attendanceDetail',
        component: () => import('../views/attendance/detail.vue'),
      },
      {
        path: '/attendance/detail/track',
        name: 'attendanceDetailTrack',
        component: () => import('../views/attendance/track.vue'),
      },

      {
        path: '/receiveNotice', // 消息通知
        name: 'receiveNotice',
        component: () => import('../views/receiveNotice/index.vue'),
      },
      {
        path: '/receive/custom/add',
        name: 'customNoticeAdd',
        component: () => import('../views/receiveNotice/customNotice/add.vue'),
      },
      {
        path: '/receive/custom/detail',
        name: 'customNoticeDetail',
        component: () => import('../views/receiveNotice/customNotice/detail.vue'),
      },
      {
        path: '/receive/custom/edit',
        name: 'customNoticeEdit',
        component: () => import('../views/receiveNotice/customNotice/edit.vue'),
      },
      {
        path: '/sendNotice',
        name: 'sendNotice',
        component: () => import('../views/sendNotice/index.vue'),
      },

      {
        path: '/apkManage', // apk列表
        name: 'apkManage',
        component: () => import('../views/apkManage/index.vue'),
      },
      {
        path: '/apkManage/add',
        name: 'apkManageAdd',
        component: () => import('../views/apkManage/add.vue'),
      },

      {
        path: '/userAccount', // 用户台账
        name: 'userAccount',
        component: () => import('../views/userAccount/index.vue'),
      },
      {
        path: '/waterAccount', // 水表台账
        name: 'waterAccount',
        component: () => import('../views/waterAccount/index.vue'),
      },
      {
        path: '/waterArchives', // 水表档案
        name: 'waterArchives',
        component: () => import('../views/waterArchives/index.vue'),
      },
      {
        path: '/waterMeter', // 水表属性
        name: 'waterMeter',
        component: () => import('../views/waterMeter/index.vue'),
      },

      {
        path: '/regionStructure', // 区域架构
        name: 'regionStructure',
        component: () => import('../views/regionStructure/index.vue'),
      },

      {
        path: '/regionManage', // 区域管理
        name: 'regionManage',
        component: () => import('../views/regionManage/index.vue'),
      },
      {
        path: '/regionManage/add',
        name: 'regionManageAdd',
        component: () => import('../views/regionManage/add.vue'),
      },
      {
        path: '/regionManage/edit',
        name: 'regionManageEdit',
        component: () => import('../views/regionManage/edit.vue'),
      },

      {
        path: '/bookManage', // 册本管理
        name: 'bookManage',
        component: () => import('../views/bookManage/index.vue'),
      },
      {
        path: '/bookManage/add',
        name: 'bookManageAdd',
        component: () => import('../views/bookManage/add.vue'),
      },
      {
        path: '/bookManage/edit',
        name: 'bookManageEdit',
        component: () => import('../views/bookManage/edit.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    next('/login')
  } else {
    next()
  }
  // console.log('to', to)
  // console.log('from', from)
  // console.log('next', next)
})

export default router
