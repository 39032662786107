const urls = {
    accountLogin: '/account/wapi/v1/management/login', //登录

    menuManageCreate: '/contact/wapi/v1/meun/create', // 创建菜单
    menuManageDelete: '/contact/wapi/v1/meun/delete', // 删除菜单
    menuManage: '/contact/wapi/v1/meun/queryAll', // 查询菜单
    menuManageUpdate: '/contact/wapi/v1/meun/update', // 修改菜单
    menuStructure: '/contact/wapi/v1/meun/query/tree', // 查询所有菜单结构 
    menuCompany: '/contact/wapi/v1/meun/query/company/menu/tree', // 查询公司支持菜单权限

    companyCreate: '/contact/wapi/v1/company/create', //创建公司
    companyDelete: '/contact/wapi/v1/company/delete', //删除公司
    companyQueryAll: '/contact/wapi/v1/company/queryAll', //查询公司
    companyUpdate: '/contact/wapi/v1/company/update', //修改公司
    companyDetail: '/contact/wapi/v1/company/query/detail', // 公司详情
    
    positionCreate: '/contact/wapi/v1/position/create', //创建岗位
    positionDelete: '/contact/wapi/v1/position/delete', //删除岗位
    positionQueryAll: '/contact/wapi/v1/position/queryAll', //查询岗位
    positionUpdate: '/contact/wapi/v1/position/update', //编辑岗位

    departmentCreate: '/contact/wapi/v1/department/create', //创建部门
    departmentDelete: '/contact/wapi/v1/department/delete', //删除部门
    departmentTree: '/contact/wapi/v1/department/query/tree', //部门列表
    departmentUpdate: '/contact/wapi/v1/department/update', //修改部门
    departmentSecondLevel: '/contact/wapi/v1/department/query/second/level', //二级部门列表

    departmentResponsibleChild: '/contact/wapi/v1/department/query/responsible/child/tree',//查询人员管辖子部门
    departmentResponsibleSecondLevel: '/contact/wapi/v1/department/query/responsible/second/level',//查询人员管辖二级部门（巡点检选择巡视点对应部门）
    
    departmentChild: '/contact/wapi/v1/department/query/child/tree',//创建巡点检查询同部门人员(暂时不用)

    staffCreate: '/contact/wapi/v1/staff/create', //员工
    staffDelete: '/contact/wapi/v1/staff/delete',
    staffDueryAll: '/contact/wapi/v1/staff/queryAll',
    staffUpdate: '/contact/wapi/v1/staff/update',
    queryFilter: '/contact/wapi/v1/staff/query/filter',
    staffDetail: '/contact/wapi/v1/staff/detail',

    entWechatRobotCreate: '/contact/wapi/v1/ent-wechat/robot/create', //机器人管理
    entWechatRobotQuery: '/contact/wapi/v1/ent-wechat/robot/query/list',
    entWechatRobotUpdate: '/contact/wapi/v1/ent-wechat/robot/update',
    entWechatRobotDelete: '/contact/wapi/v1/ent-wechat/robot/delete',

    typeCreate: '/device/wapi/v1/type/create', //设备类型
    typeDelete: '/device/wapi/v1/type/delete',
    typeQueryAll: '/device/wapi/v1/type/queryAll',
    typeUpdate: '/device/wapi/v1/type/update',

    facilityCreate: '/device/wapi/v1/facility/create', //设施管理
    facilityDelete: '/device/wapi/v1/facility/delete',
    facilityQueryAll: '/device/wapi/v1/facility/queryAll',
    facilityUpdate: '/device/wapi/v1/facility/update',

    statusCreate: '/device/wapi/v1/status/create', // 设备状态
    statusDelete: '/device/wapi/v1/status/delete',
    statusQueryAll: '/device/wapi/v1/status/queryAll',
    statusUpdate: '/device/wapi/v1/status/update',

    deviceCreate: '/device/wapi/v1/archives/create', //设备信息 
    deviceDelete: '/device/wapi/v1/archives/delete',
    deviceChecklist: '/device/wapi/v1/archives/filter/query/checklist',
    deviceDetail: '/device/wapi/v1/archives/query/detail',
    deviceUpdate: '/device/wapi/v1/archives/update',
    deviceStatus: '/device/wapi/v1/info/update/status',

    scrapDevice: '/device/wapi/v1/scrap/filter/query', //报废设备

    patrolCreate: '/device/wapi/v1/patrol/create', //设备巡视点
    patrolDelete: '/device/wapi/v1/patrol/delete',
    patrolList: '/device/wapi/v1/patrol/filter/query/list',
    patrolDetail: '/device/wapi/v1/patrol/query/detail',
    patrolUpdate: '/device/wapi/v1/patrol/update',
    patrolDeviceList: '/device/wapi/v1/patrol/query/device/list',

    lubeDeviceList: '/device/wapi/v1/patrol/query/lube/device/list', // 过滤有润滑内容的设备
    // 检修管理
    reconditionTask: '/recondition/wapi/v1/task/query/list', // 检修计划
    reconditionTaskCreate: '/recondition/wapi/v1/task/new/create',
    reconditionTaskDel: '/recondition/wapi/v1/task/delete',
    reconditionUpdate: '/recondition/wapi/v1/task/edit',
    rDeviceDetail: '/device/wapi/v1/patrol/query/device/detail', // 检修计划选择设备获取设备详情

    reconditionDynamic: '/recondition/wapi/v1/task/query/news/list', // 检修任务
    reconditionDynamicDetail: '/recondition/wapi/v1/task/query/detail',
    reconditionDynamicContinue: '/recondition/wapi/v1/task/create/continue',
    reconditionDynamicStatus: '/recondition/wapi/v1/task/update/status',
    reconditionContinueUpdate: '/recondition/wapi/v1/task/continue/edit',
    reconditionContinue: '/recondition/api/v1/management/update/status', //修改异常为继续检修

    reconditionRecord: '/recondition/wapi/v1/record/query/list', // 检修记录
    reconditionRecordDetail: '/recondition/wapi/v1/record/query/detail',

    // 润滑管理
    lubeTask: '/lube/wapi/v1/rule/query/list', // 润滑计划
    lubeTaskCreate: '/lube/wapi/v1/rule/create',
    lubeTaskUpdate: '/lube/wapi/v1/rule/update/status',
    lubeTaskDelete: '/lube/wapi/v1/rule/delete',
    lubeTaskDetail: '/lube/wapi/v1/rule/query/detail',
    lubeTaskUpdates: '/lube/wapi/v1/rule/update/details',

    lubeDynamic: '/lube/wapi/v1/task/query/day/news/list', // 润滑任务
    lubeDynamicInfo: '/lube/wapi/v1/task/query/day/news/statistics',

    lubeRecord: '/lube/wapi/v1/record/query/list', // 润滑记录
    lubeRecordDetail: '/lube/wapi/v1/record/query/detail',

    lubeManager: '/lube/wapi/v1/manager/archives/query/list', // 润滑油档案
    lubeManagerCreate: '/lube/wapi/v1/manager/archives/create',
    lubeManagerDelete: '/lube/wapi/v1/manager/archives/delete',
    lubeManagerDetail: '/lube/wapi/v1/manager/archives/query/detail',
    lubeManagerEdit: '/lube/wapi/v1/manager/archives/edit',

    lubeBrand: '/lube/wapi/v1/brand/query/list', //润滑油品牌
    lubeBrandCreate: '/lube/wapi/v1/brand/create',
    lubeBrandDelete: '/lube/wapi/v1/brand/delete',
    lubeBrandEdit: '/lube/wapi/v1/brand/edit',

    lubeClassification: '/lube/wapi/v1/classification/query/list', //润滑油分类
    lubeClassificationCreate: '/lube/wapi/v1/classification/create',
    lubeClassificationDelete: '/lube/wapi/v1/classification/delete',
    lubeClassificationEdit: '/lube/wapi/v1/classification/edit',

    lubeLevel: '/lube/wapi/v1/level/query/list', //润滑油等级
    lubeLevelCreate: '/lube/wapi/v1/level/create',
    lubeLevelDelete: '/lube/wapi/v1/level/delete',
    lubeLevelEdit: '/lube/wapi/v1/level/edit',

    // IOT管理
    iotSupplierQuery:'/conn-portal/wapi/v1/supplier/admin/queryAll',//供应商
    iotSupplierCreate:'/conn-portal/wapi/v1/supplier/admin/create',
    iotSupplierDelete:'/conn-portal/wapi/v1/supplier/admin/delete',

    supplierDeviceQuery:'/conn-portal/wapi/v1/supplier/admin/equipment/query',//供应商设备
    supplierDeviceCreate:'/conn-portal/wapi/v1/supplier/admin/equipment/create',

    iotEquipmentQuery: '/conn-portal/wapi/v1/equipment/queryAll',//设备管理
    iotEquipmentCreate: '/conn-portal/wapi/v1/equipment/create',
    iotEquipmentDelete: '/conn-portal/wapi/v1/equipment/delete',
    iotEquipmentImport: '/conn-portal/wapi/v1/equipment/import/pointTable',
    deviceTypeByEquipment: '/conn-portal/wapi/v1/equipment/query/by/deviceType', //根据设备类型Code查设备
    supplierByEquipment: '/conn-portal/wapi/v1/equipment/query/by/supplier/brand',//根据供应商和品牌Code查询设备

    equipmentByEcode: '/conn-portal/wapi/v1/equipment/query/equipment/by/ecode',//根据设备型号查询设备列表

    equipmentGroupQuery: '/conn-portal/wapi/v1/equipment/query/equipment/group',//数据采集
    gatewayBind: '/conn-portal/wapi/v1/gateway/bind',//网关子设备绑定
    subGetewayQuery: '/conn-portal/wapi/v1/gateway/query/geteway/sub',//查询网关对应子设备
    reportEquimentDataQuery: '/conn-portal/wapi/v1/report/batch/query/latest/report/data',//查询设备上报数据
    reportRecordQuery: '/conn-portal/wapi/v1/report/query/record',//历史记录
    deviceReport: '/conn-portal/wapi/v1/report/query/latest/list/by/deviceType',//设备上报
    waterWorksQuery: '/conn-portal/wapi/v1/equipment/query/waterworks/list',//查询水厂列表

    equipRecordTitle: '/conn-portal/wapi/v1/report/query/equipment/record/title',// 数据采集历史记录-表头
    equipRecordContent: '/conn-portal/wapi/v1/report/query/equipment/record',// 数据采集历史记录-内容

    iotDeviceQuery: '/conn-portal/wapi/v1/device/type/queryAll',//设备类型

    importPointTable: '/conn-portal/wapi/v1/equipment/import/pointTable',//导入设备点表

    // importTwins: '/conn-portal/wapi/v1/uino/import/digital/twin/excel',//导入孪生体数据文件
    iotUinoProjectQuery: '/conn-portal/wapi/v1/uino/project/query/list',//查询uino项目列表
    iotUinoClassifQuery: '/conn-portal/wapi/v1/uino/digital/twin/query/park/list',//查询uino园区列表
    iotTwinsQuery: '/conn-portal/wapi/v1/uino/digital/twin/query/list',//查询uino孪生体列表
    iotTagsQuery: '/conn-portal/wapi/v1/equipment/query/pointTable/by/eid',//tags列表
    iotBindTwinsData: '/conn-portal/wapi/v1/uino/digital/twin/bind/data/source',//绑定孪生体数据源信息
    iotUnbindTwinsData: '/conn-portal/wapi/v1/uino/digital/twin/unbind/data/source',//解绑孪生体数据源信息
    iotTwinsDetail: '/conn-portal/wapi/v1/uino/digital/twin/query/bind/detail',//查询孪生体绑定详情
    iotTwinsUpdate: '/conn-portal/wapi/v1/uino/digital/twin/edit/bind',//孪生体绑定编辑
    iotEquipmentDetail: '/conn-portal/wapi/v1/equipment/query/detail/by/eid',//查询iot设备详情
    iotAbilityQuery: '/conn-portal/wapi/v1/equipment/query/abilityTable/by/eid',//查询非PLC点表

    uinoDigitalTwinTypeQuery: '/conn-portal/wapi/v1/uino/digital/twin/type/query/list',//查询孪生体项目下自定义分类
    uinoDigitalTwinTypeCreate: '/conn-portal/wapi/v1/uino/digital/twin/type/create',//创建自定义孪生体分类
    uinoDigitalTwinTypeDistribute:'/conn-portal/wapi/v1/uino/digital/twin/type/distribute',//根据自定义孪生体分类查询对应孪生体数据
    uinoDigitalTwinTypeDelete: '/conn-portal/wapi/v1/uino/digital/twin/type/delete',//删除自定义孪生体分类

    // 组态项目
    configProjectQuery: '/conn-portal/wapi/v1/configuration/project/query/list',//查询组态项目列表
    configProjectCreate: '/conn-portal/wapi/v1/configuration/project/create',//创建组态项目
    configProjectDetail: '/conn-portal/wapi/v1/configuration/project/query/detail',
    configProjectEdit: '/conn-portal/wapi/v1/configuration/project/update',
    configProjectDelete: '/conn-portal/wapi/v1/configuration/project/delete',
    // 组态管理
    configurationQuery: '/conn-portal/wapi/v1/configuration/manage/query/list',//查询组态列表
    configurationCreate: '/conn-portal/wapi/v1/configuration/manage/create',//创建组态
    configManageCover: '/conn-portal/wapi/v1/configuration/manage/create/cover',//组态封面截图

    configurationUpdate: '/conn-portal/wapi/v1/configuration/manage/update',//编辑组态
    configUpdateCover: '/conn-portal/wapi/v1/configuration/manage/update/cover',//组态封面更新

    configurationDetail: '/conn-portal/wapi/v1/configuration/manage/query/detail',//查询组态详情
    configurationDelete: '/conn-portal/wapi/v1/configuration/manage/delete',//删除组态

    configButtonPushData: '/conn-portal/wapi/v1/configuration/operation/button',//点击按钮下发数据
    configSwitchPushData: '/conn-portal/wapi/v1/configuration/operation/switch',//点击开关下发数据
    configOutputPushData: '/conn-portal/wapi/v1/configuration/operation/order/output',//编辑文本下发数据

    configFirstReportCache: '/conn-portal/wapi/v1/configuration/manage/query/latest/report/cache',//查询组态首次缓存数据
    
    // reportConfigTitle: '/conn-portal/wapi/v1/report/query/configuration/title',//查询组态详情中历史记录表头
    // reportConfigRecord: '/conn-portal/wapi/v1/report/query/configuration/record',//查询组态详情中历史记录内容
    reportConfigTitle: '/conn-portal/wapi/v1/configuration/project/query/equipment/tag/list',//查询组态详情中历史记录表头
    reportConfigRecord: '/conn-portal/wapi/v1/report/query/configuration/record',//查询组态详情中历史记录内容

    // 组态操作记录
    projectEquipmentQuery: '/conn-portal/wapi/v1/configuration/project/query/equipment/list',//查询单个项目下所有设备列表

    //组态资源
    resourceQuery: '/conn-portal/wapi/v1/configuration/manage/query/resources',//获取组态资源列表
    resourceCreate: '/conn-portal/wapi/v1/configuration/manage/admin/create/resource',//创建组态资源
    resourceDelete: '/conn-portal/wapi/v1/configuration/manage/delete/resources',//删除组态资源
    
    // 组态操作记录
    configOperaRecord: '/conn-portal/wapi/v1/configuration/operation/query/record/list',//查询操作记录列表

    // 数据统计
    iotSingleEquipmentWorkData: '/conn-portal/wapi/v1/statistics/query/single/equipment/working/data',//查询单台设备工作数据统计
    iotTrendWorkData: '/conn-portal/wapi/v1/statistics/query/equipment/trend/working/data',//查询设备趋势图数据
    iotRealtimeData: '/conn-portal/wapi/v1/statistics/query/equipment/realtime/data',//查询设备实时数据
    iotAllTrendWorkData: '/conn-portal/wapi/v1/statistics/query/all/equipment/trend/working/data',//查询总累计流量
    
    // 图表配置
    dataModelItemQuery: '/conn-portal/wapi/v1/data/model/item/query/list',//查询图表分类
    dataModelItemCreate: '/conn-portal/wapi/v1/data/model/item/create',//创建图表分类
    dataModelItemEdit: '/conn-portal/wapi/v1/data/model/item/edit',//编辑图表分类

    dataModelGraphListQuery: '/conn-portal/wapi/v1/data/model/graph/query/graph/list',//查询指定分类下图表列表
    dataModelGraphInfoQuery: '/conn-portal/wapi/v1/data/model/graph/query/graph/info',//查询单个图表基本信息
    dataModelItemByQuery: '/conn-portal/wapi/v1/data/model/graph/query/graph/data',//查询单个图表数据
    
    equipmentByAbilityQuery: '/conn-portal/wapi/v1/equipment/query/equipment/group/by/ability',//查询过滤后的设备 ability: 0 iot设备 1 plc设备 2 网关

        // 实时图表创建
    dataModelGraphRealtimeCreate: '/conn-portal/wapi/v1/data/model/graph/create/realtime',
    // dataModelGraphGroupRealtimeCreate: '/conn-portal/wapi/v1/data/model/graph/create/realtime',
        // 折线图首次请求数据
    dataModelGraphDailyReportQuery: '/conn-portal/wapi/v1/data/model/graph/query/daily/report',
        // 累计创建
    dataModelGraphAccumPerCreate: '/conn-portal/wapi/v1/data/model/graph/create/total',
    // dataModelGraphGroupAccumPerCreate: '/conn-portal/wapi/v1/data/model/graph/create/bar/cumulative',
        // 趋势创建
    dataModelGraphTrendCreate: '/conn-portal/wapi/v1/data/model/graph/create/trend', 

        // 合并趋势统计
    dataModelGraphMergeTrendCreate: '/conn-portal/wapi/v1/data/model/graph/create/merge/trend', 
    
    // 数据报表
    flowmeterDataQuery: '/conn-portal/wapi/v1/dingbian/query/flowmeter/data',
    // 32口水井数据统计
    dingbianStatTotaoQuery: '/conn-portal/wapi/v1/dingbian/query/total/statistics',
    dingbianExport: '/conn-portal/wapi/v1/dingbian/export/total/statistics',

    // 数据分析
    analysisPatrol: '/statistics/wapi/v1/analysis/patrol', // 巡视点
    analysisDetail: '/statistics/wapi/v1/analysis/patrol/detail',
    analysisArchives: '/statistics/wapi/v1/analysis/archives', // 设备
    archivesDetail: '/statistics/wapi/v1/analysis/archives/detail',

    ruleInspection: '/task/wapi/v1/rule/create', //创建巡检计划
    rulePatrolCreate: '/task/wapi/v1/rule/create', //创建点检计划
    ruleDelete: '/task/wapi/v1/rule/delete',
    ruleList: '/task/wapi/v1/rule/query/inspection/list',
    ruleQuery: '/task/wapi/v1/rule/list/query',  // 任务规则列表 taskType 0巡检 1点检
    ruleStatus: '/task/wapi/v1/rule/update/status',
    rulebaseDetail: '/task/wapi/v1/rule/query/base/detail', // 基本
    rulePatrolDetail: '/task/wapi/v1/rule/query/patrol/detail',  // 规则
    ruleStaffDetail: '/task/wapi/v1/rule/query/staffGroup/detail', // 人员
    ruleUpdate: '/task/wapi/v1/rule/update/details', // 编辑

    //  数据中心
    archivesRet: '/device/wapi/v1/data/center/archives/query/retrieval/list', //档案检索
    archivesDevice: '/device/wapi/v1/data/center/archives/query/device/detail', //设备详情
    archivesPatrol: '/device/wapi/v1/data/center/archives/query/device/operation/record', //巡检记录
    archivesCheck: '/device/wapi/v1/data/center/archives/query/device/operation/record', //点检记录
    archivesRecond: '/device/wapi/v1/data/center/archives/query/device/recondition/record', //检修记录
    archivesLube: '/device/wapi/v1/data/center/archives/query/device/lube/record', //润滑记录


    // 任务记录
    recordAbnormal: '/task/wapi/v1/record/abnormal/filter/query', // 异常
    recordAbnormalDetail: '/task/wapi/v1/record/abnormal/device/detail',
    recordTask: '/task/wapi/v1/record/filter/query',// taskType 0巡检 1点检
    recordTask0: '/task/wapi/v1/record/filter/query?taskType=0', // 巡检
    recordTask1: '/task/wapi/v1/record/filter/query?taskType=1', // 点检
    recordTaskDetail: '/task/wapi/v1/record/detail', // 计划详情
    recordTaskDevice: '/task/wapi/v1/record/device/detail', // 任务设备详情

    recordOmission: '/task/wapi/v1/record/miss/filter/query', // 漏检
    recordOmissionDetail: '/task/wapi/v1/record/miss/detail',

    recordRepair: '/task/wapi/v1/record/repair/filter/query', // 维修
    recordRepairDetail: '/task/wapi/v1/record/repair/device/detail',

    recordOperation: '/task/wapi/v1/record/device/operation', // 异常任务操作 0->撤销 1->检修 2->报废
    recordRecondition: '/task/wapi/v1/record/device/recondition', // 异常转检修
    turnOverhaul: '/recondition/wapi/v1/task/create/from/task', // 异常转检修提交

    // 记录导出
    recordExport: '/task/wapi/v1/export/excel',//记录导出
    fileBoxQuery: '/alarm/wapi/v1/file/box/query/list',//消息中心->待下载列表
    changeDownloadStatus: '/alarm/wapi/v1/file/box/change/download/status',//更改下载状态

    // 首页
    dashboardAnalysis: '/statistics/wapi/v1/dashboard/daily/data/analysis', // 数据分析仪
    facilityDistribute: '/statistics/wapi/v1/dashboard/facility/device/distribute', // 设备分布
    devicePercent: '/statistics/wapi/v1/dashboard/patrol/device/percent/rank', // 巡视设备占比
    deviceRank: '/statistics/wapi/v1/dashboard/patrol/device/count/rank', // 设备巡视点Top5
    deviceTypeRank: '/statistics/wapi/v1/dashboard/device/type/rank', // 巡视分类统计
    dashboardTrend: '/statistics/wapi/v1/dashboard/task/trend', // 巡点检次数趋势图
    dashboardCount: '/statistics/wapi/v1/dashboard/total/count', // 总数统计

    dashboardData: '/statistics/api/v1/dashboard/daily/operation/data', // 总数统计
    patrolDistribute: '/statistics/api/v1/dashboard/patrol/abnormal/distribute', // 巡视点异常
    deviceDistribute: '/statistics/api/v1/dashboard/device/abnormal/distribute', // 设备异常

     // 任务动态
    managementNews: '/task/wapi/v1/management/query/daily/task/news',// taskType 0巡检 1点检
    managementNews0: '/task/wapi/v1/management/query/daily/task/news?taskType=0', // 0巡检 1点检
    managementNews1: '/task/wapi/v1/management/query/daily/task/news?taskType=1',// 0巡检 1点检
    managementStatistics: '/task/wapi/v1/management/query/daily/task/news/data/statistics',

    // 漏检重置
    queryModiyLogs: '/task/wapi/v1/rule/query/modify/logs',//查询漏检重置记录列表
    queryCycleFilterList: '/task/wapi/v1/rule/filter/query/list',//按照条件查询任务规则列表
    queryCycleFilterDetail: '/task/wapi/v1/rule/query/patrol/detail',//按照条件查询任务规则列表
    rulemodifyTaskStatus: '/task/wapi/v1/rule/modify/task/status',//按照条件查询任务规则列表

    // 知识库 
    knowledgeList: '/knowledge/wapi/v1/content/query/module', //知识库列表
    knowledgeCreate: '/knowledge/wapi/v1/content/create',
    knowledgeDetail: '/knowledge/wapi/v1/content/query/detail',
    knowledgeEdit: '/knowledge/wapi/v1/content/edit',
    knowledgeDelete: '/knowledge/wapi/v1/content/delete',
    
    columnClassify: '/knowledge/wapi/v1/module/query', // 栏目分类
    columnCreate: '/knowledge/wapi/v1/module/create',
    columnEdit: '/knowledge/wapi/v1/module/update',
    columnDelete: '/knowledge/wapi/v1/module/delete',

    queryDepartmentStaff: '/contact/wapi/v1/department/query/base/two/level', // 查询一，二级部门组织结构及人员信息
    queryStaffPosition: '/location/wapi/v1/manage/query/last/position', // 查询指定人员最近一次位置信息
    queryStaffMileage: '/location/wapi/v1/manage/query/mileage', // 查询人员相关里程信息
    queryStaffTrack: '/location/wapi/v1/manage/query/mileage/trajectory', // 查询指定里程人员轨迹
    staffMileage: '/location/wapi/v1/manage/query/staff/mileage', // 员工里程表

    queryAttendance: '/location/wapi/v1/routine/query/attendance/list', // 查询出勤列表
    queryAttendanceDetail: '/location/wapi/v1/routine/query/attendance/item/detail', //查询出勤列表详情

    routineQuery: '/location/wapi/v1/field-plan/query/list', // 外勤计划
    routineCreate: '/location/wapi/v1/field-plan/create',
    routineDetail: '/location/wapi/v1/routine/query/detail',
    routineEdit: '/location/wapi/v1/routine/edit',
    routineDelete: '/location/wapi/v1/routine/delete',
    staffByRegion: '/contact/wapi/v1/staff/query/by/region', //根据区域查询对应人员

    fieldTaskQuery: '/location/wapi/v1/field-plan/query/sub/task/list', //外勤任务

    specificQuery: '/location/wapi/v1/specific/query/list', // 指派任务
    specificCreate: '/location/wapi/v1/specific/task/create',
    specificDetail: '/location/wapi/v1/specific/query/detail',
    specificEdit: '/location/wapi/v1/specific/edit',
    specificDelete: '/location/wapi/v1/specific/delete',

    faulReportQuery: '/location/wapi/v1/abnormal/query/report/list', //故障上报
    faulReportDetail: '/location/wapi/v1/abnormal/query/report/detail',

    checkReportQuery: '/location/wapi/v1/abnormal/query/check/list', //巡查上报
    checkReportDetail: '/location/wapi/v1/abnormal/query/check/detail',

    alarmMessageQuery: '/alarm/wapi/v1/message/filter/query/list',//查询告警信息
    alarmMessageHandle: '/alarm/wapi/v1/message/handle',

    alarmRulesQuery: '/alarm/wapi/v1/rule/query/list',//查询告警规则
    alarmRulesCreate: '/alarm/wapi/v1/rule/create',
    alarmRulesDetail: '/alarm/wapi/v1/rule/query/detail',
    alarmRulesEdit: '/alarm/wapi/v1/rule/edit',
    alarmRulesDelete: '/alarm/wapi/v1/rule/delete',

    alarmReportQuery: '/alarm/wapi/v1/daily/report/filter/query/list',//查询日报

    noticeReceiverQuery: '/alarm/wapi/v1/notice/query/list/by/receiver', // 消息通知
    noticeCreate: '/alarm/wapi/v1/notice/create',
    noticeDetail: '/alarm/wapi/v1/notice/query/detail',
    noticeDelete: '/alarm/wapi/v1/notice/delete',
    noticeSendQuery: '/alarm/wapi/v1/notice/query/send/list',

    timeNoticeQuery: '/alarm/wapi/v1/timer/notice/query/list',//定时通知
    timeNoticeCreate: '/alarm/wapi/v1/timer/notice/create',
    timeNoticeDetail: '/alarm/wapi/v1/timer/notice/query/detail',
    timeNoticeUpdate: '/alarm/wapi/v1/timer/notice/edit',
    timeNoticeDelete: '/alarm/wapi/v1/timer/notice/delete',

    latestCountQuery: '/alarm/wapi/v1/message/box/query/latest/count',//查询所有通知数量
    reportHandleAll: '/alarm/wapi/v1/daily/report/handle/all',//所有日报消通知已读
    noticeReadAll: '/alarm/wapi/v1/notice/read/all',//所有通知消息已读

    cycleAchievement: '/task/wapi/v1/achievement/query/operation/month/list',//个人绩效
    cycleAnalysisReport: '/task/wapi/v1/export/operation/analysis/report',//巡检分析报告pdf导出

    otaQuery: '/ota/wapi/v1/manage/query', // ota列表
    otaCreate: '/ota/wapi/v1/manage/upload/apk',
    otaDelete: '/ota/wapi/v1/manage/delete',

    regionQuery: '/contact/wapi/v1/region/queryAll',//区域管理
    regionCreate: '/contact/wapi/v1/region/create',
    regionDelete: '/contact/wapi/v1/region/delete',
    regionUpdate: '/contact/wapi/v1/region/update',
    
    zoneQuery: '/contact/wapi/v1/zone/queryAll',//册本管理
    zoneCreate: '/contact/wapi/v1/zone/create',
    zoneDelete: '/contact/wapi/v1/zone/delete',
    zoneUpdate: '/contact/wapi/v1/zone/update',
    
    regionStructureQuery: '/contact/wapi/v1/region/query/structure',//区域结构

    userAccountExcel: '/device/wapi/v1/water-meter/user/book/import/meter/excel',//导入用户台账
    userAccountQuery: '/device/wapi/v1/water-meter/user/book/filter/query',

    meterExcel:'/device/wapi/v1/water-meter/book/import/meter/excel',//导入水表台账
    meterQuery: '/device/wapi/v1/water-meter/book/filter/query',//水表台账
    qrcodeQuery: '/device/wapi/v1/water-meter/book/query/qrcode',//查询水表二维码

    meterAttrQuery: '/device/wapi/v1/dxnry/water-meter/attribute/query/by/code',//水表属性
    meterAttrCreate: '/device/wapi/v1/dxnry/water-meter/attribute/create',
    meterAttrDelete: '/device/wapi/v1/dxnry/water-meter/attribute/delete',
    meterAttrUpdate: '/device/wapi/v1/dxnry/water-meter/attribute/update',

    changePassword: '/account/wapi/v1/management/change/password', // 修改密码
    createAdmin: '/contact/wapi/v1/staff/create/admin', // 创建管理员
    //  queryAdmin: '/account/wapi/v1/management/query/admin' // 查询所有管理员
    queryAdmin: '/contact/wapi/v1/staff/query/admin', // 查询所有管理员

    // test
    generate: '/task/wapi/v1/test/create', // 生成
    init: '/task/wapi/v1/test/delete', // 初始化

    generateLube: '/lube/wapi/v1/test/create', // 润滑生成
    initLube: '/lube/wapi/v1/test/delete ', // 润滑初始化
}

export default urls